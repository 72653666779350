import { Routes } from '@angular/router';
import { MainContentComponent } from './Components/maincontent.component/maincontent.component';
import { SigninRedirectCallbackComponent } from './signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './signout-redirect-callback.component';
const appRoutes: Routes = [
  { path: 'signin-callback', component: SigninRedirectCallbackComponent },
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
  { path: '', component: MainContentComponent },

  {
    path: 'exel',
    loadChildren: () => import('./exel/exel.module').then((m) => m.ExelModule),
  },
  {
    path: 'report',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'restaurant',
    loadChildren: () =>
      import('./restaurant/restaurant.module').then((m) => m.RestaurantModule),
  },
  {
    path: 'cat',
    loadChildren: () =>
      import('./categories/categories.module').then((m) => m.CategoriesModule),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./store-users/store-users.module').then(
        (m) => m.StoreUsersModule
      ),
  },
];
export default appRoutes;
