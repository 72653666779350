import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { EMPTY, from, Observable, tap } from "rxjs";
import { AuthService } from "./auth.service";
@Injectable()
export class OAuthInterceptor implements HttpInterceptor {
    constructor(private authService :  AuthService,private router : Router){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (req.url.includes('/api')) {
        return from(this.authService.getAccessToken().then(token => {
          var savedLanguage = localStorage.getItem('selectedLanguage');
          var acceptLanguage = "ar-EG";
          if (savedLanguage == null) {
            acceptLanguage = "ar-EG";
          }
          else if (savedLanguage == "en") {
            acceptLanguage = "en";
          }

          else {
            acceptLanguage = "ar-EG";
          }
          const headers = req.headers.append('Authorization', `Bearer ${token}`)
            .append('Accept-Language', acceptLanguage);
          const authReq = req.clone({ headers });
          return next.handle(authReq).pipe(tap(_ => {EMPTY }, error => {
            const respError = error as HttpErrorResponse;
            if (respError && (respError.status === 401 || respError.status === 403)) {
              this.router.navigate(['/']);
            }
          })).toPromise();
        })) as Observable<HttpEvent<any>>;
      }
      else {
        return next.handle(req);
      }
    }
  }
    
    
