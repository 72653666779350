import { Injectable } from "@angular/core";
import * as signalR from '@microsoft/signalr';
import { AppConfigService } from "./AppConfigService";
import { AuthService } from "./auth.service";


@Injectable()
export class RestaurantSignalRServices{
  connection:signalR.HubConnection;

  constructor(public config: AppConfigService,
    private authService :  AuthService){
      this.authService.loginChanged$.subscribe(isAuthenticated => {

        if(isAuthenticated && !this.connection){

          this.connection = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Information)
          .withUrl(`${this.config.getConfig()?.restaurantBaseUrl}/operationHub`, { accessTokenFactory: () => this.authService.getAccessToken() })
          .withAutomaticReconnect()
          .build();
          
          
          this.connection.start().then(function () {
            console.log('SignalR Connected!');
          }).catch(function (err) {
            console.error(err.toString());
          });    
        }
      });
  }

GethubConnection():signalR.HubConnection{

  return this.connection;
}

}






