import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { BehaviorSubject, catchError, EMPTY } from "rxjs";
import { AppConfig } from "./AppConfig";

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private envUrl = 'assets/appSettings.json';
  private config: AppConfig;
  loaded$ = new BehaviorSubject<boolean>(false);
  loaded: boolean;
  constructor(protected inej: Injector) { }
  loadConfig(): Promise<any> {

    return new Promise((resolve, reject) => {
      const http = this.inej.get(HttpClient);
      http.get(this.envUrl)
        .pipe(catchError(err => {
          console.log(err);
          return EMPTY;
        }))
        .subscribe((response: any) => {
          this.config = response;
          this.loaded = true;
          if (!this.loaded$.value) {
            this.loaded$.next(true);

          }
          resolve(true);
        }, (err) => { console.log(err) });
    });
  }



  getConfig(): AppConfig {
    return this.config;
  }

  get Config(): AppConfig {
    return this.config;
  }
}
