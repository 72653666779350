import { Injectable } from '@angular/core';
import { UserManager, User, WebStorageStateStore } from 'oidc-client';
import { Subject } from 'rxjs';
import { AppConfigService } from './AppConfigService';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _userManager: UserManager;
  private _user: User | null;
  private _loginChangedSubject = new Subject<boolean>();
  public loginChanged$ = this._loginChangedSubject.asObservable();
  private clientRoot: string;
  private clientId: string;
  private idpAuthority: string;

  constructor(private appConfig: AppConfigService) {
    const res = this.appConfig.loaded$.subscribe({
      next: () => {
        this.clientRoot = this.appConfig.getConfig()?.restaurantBaseUrl;
        this.clientId = this.appConfig.getConfig()?.clientId;
        this.idpAuthority = this.appConfig.getConfig()?.idpAuthority;
        this._userManager = new UserManager({
          authority: this.idpAuthority,
          client_id: this.clientId,
          redirect_uri: `${this.clientRoot}/signin-callback`,
          scope: 'openid profile AngularApis offline_access',
          response_type: 'code',
          post_logout_redirect_uri: `${this.clientRoot}signout-callback`,
          loadUserInfo: true,
          userStore: new WebStorageStateStore({ store: window.localStorage }),
          filterProtocolClaims: false,
        });
      },
    });
  }

  public login = () => {
    return this._userManager.signinRedirect();
  };

  public logout = () => {
    return this._userManager.signoutRedirect();
  };

  public isAuthenticated = (): Promise<boolean> => {
    return this._userManager.getUser().then((user) => {
      if (this._user !== user) {
        this._loginChangedSubject.next(this.checkUser(user));
      }
      this._user = user;
      return this.checkUser(user);
    });
  };

  public GetUser = (): Promise<User | null> => {
    return this._userManager.getUser();
  };

  private refreshAccessToken() {

    if(!this._user)
      return;

    return this._userManager.signinSilent().then((user) => {
      this._user = user;
      this._loginChangedSubject.next(!!user && !user.expired);
    }).catch((error) => {

      console.error('Silent token renewal failed', error);
    });
  }

  public getAccessToken() {
    return this._userManager.getUser().then((user) => {
      
      if(!user)
        return '';

      if (user.expires_in < 30 || user.expired) {
        return this.refreshAccessToken()!.then(() => user.access_token);
      }

      return user.access_token;
    });
  }

  public completelogin() {
    return this._userManager.signinRedirectCallback().then((user) => {
      this._user = user;
      this._loginChangedSubject.next(!!user && !user.expired);
      return user;
    });
  }

  public completeLogout() {
    this._user = null;
    return this._userManager.signoutRedirectCallback();
  }

  public checkUser = (user: User | null): boolean => {
    return !!user && !user.expired;
  };
}
