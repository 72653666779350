import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";


//@Injectable({
//  providedIn: 'root',
//})
@Injectable()

export class _8OrdersToastrService{
  audio: HTMLAudioElement = new Audio("../../../assets/mixkit-musical-reveal-961.wav");
        constructor(private toastrService : ToastrService ){}

        success(message:string , title :string){
            this.toastrService.success(message,title,{
               timeOut:1000,
               closeButton:true,

            });
        }
        info(message:string , title :string){
            this.toastrService.info(message,title,{
                timeOut:3000,
                closeButton:true,

             });
        }
        warning(message:string , title :string){
            this.toastrService.warning(message,title,{
                timeOut:1000,
                closeButton:true,

             });
        }
        error(message:string , title :string){
            this.toastrService.error(message,title,
                {
                    timeOut:2000,
                    closeButton:true,

                 });
        }

        playAudio() {
         
          this.audio.loop = true;
          if (this.audio.paused == true) {
            this.audio.play();
          }
        }

        pauseAudio() {
          this.audio.pause();
        }
}
