import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserPermissionsClient } from 'src/app/Shared/services/8OrdersRestaurantsclient';
import { AuthService } from 'src/app/Shared/services/auth.service';

@Component({
  selector: 'main-content',
  templateUrl: './maincontent.component.html',
  styleUrls: ['./maincontent.css'],
})
export class MainContentComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  public userAuthenticated = false;
  role: string;
  subUserId: string;
  userId: number;
  isRestaurantAdmin: boolean = false;
  isRestaurant: boolean = false;
  isAdmin: boolean = false;
  hasPermission: boolean = false;
  restaurantId: number = 0;
  constructor(
    private authService: AuthService,
    private permissionClient: UserPermissionsClient
  ) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  ngOnInit(): void {
    const restaurantId = localStorage.getItem(
      'restaurantIdFromAdmin'
    ) as string;
    this.restaurantId = parseInt(restaurantId);

    this.authService.GetUser().then((x) => {
      this.role = x?.profile['role'];
      this.role == 'RestaurantAdmin'
        ? (this.isRestaurantAdmin = true)
        : (this.isRestaurantAdmin = false);

      this.role == 'restaurant'
        ? (this.isRestaurant = true)
        : (this.isRestaurant = false);

      this.role?.toLocaleLowerCase() ?? '' === 'admin'.toLocaleLowerCase()
        ? (this.isAdmin = true)
        : (this.isAdmin = false);
    });

    this.authService.isAuthenticated().then(isAuthenticated => 
     {
       this.userAuthenticated = isAuthenticated;
       
       if(isAuthenticated)
        this.GetUserPermission();
     }
    );
    
    this.sub = this.authService.loginChanged$.subscribe((isAuthenticated) => {
      this.userAuthenticated = isAuthenticated;
    });
  }

  GetUserPermission() {
    
    this.authService.GetUser().then((x) => {
      this.subUserId = x?.profile['sub'] as string;
      this.userId = parseInt(this.subUserId);

      this.permissionClient
        .getUserPermissions(this.userId)
        .subscribe((data) => {
          const filteredList = data.filter(
            (permission) => permission === 'Restaurants'
          );
          if (filteredList.length > 0) {
            this.hasPermission = true;
          }
        });
    });
  }
}
